import Button, { ButtonProps } from '@/components/atoms/legacy/Button';
import { AuthDialog } from '@/components/organisms/AuthDialog/AuthDialog';
import { useAuth } from '@/hooks/useAuth';
import { HeartIcon } from '@heroicons/react/24/solid';
import { useCallback, useState } from 'react';

export interface HeartButtonProps
  extends Omit<
    ButtonProps,
    | 'children'
    | 'variant'
    | 'icon'
    | 'className'
    | 'withArrow'
    | 'analyticsEvent'
  > {
  onClick: (
    e?:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.TouchEvent<HTMLButtonElement>
  ) => Promise<void>;
  saved: boolean;
  size?: 'small' | 'medium';
  dark?: boolean;
  /** A remote image URL of the vehicle to show in `AuthDialog` */
  authDialogImage: string;
}

export const HeartButton: React.FC<HeartButtonProps> = ({
  saved,
  size = 'medium',
  dark = false,
  loading,
  onClick,
  authDialogImage,
  ...rest
}) => {
  const iconSize = size === 'small' ? 16 : 20;
  const buttonDimensions = size === 'small' ? 24 : 40;
  const backgroundClassName = dark
    ? 'bg-black hover:bg-neutral-800'
    : `hover:bg-white ${saved ? 'bg-white' : 'bg-white/80'}`;

  const { user } = useAuth();

  const [isAuthDialogOpen, setIsAuthDialogOpen] = useState(false);

  const handleButtonAction = useCallback(
    async (
      e:
        | React.MouseEvent<HTMLButtonElement>
        | React.TouchEvent<HTMLButtonElement>
    ) => {
      e.stopPropagation();
      e.preventDefault();
      await onClick();
      if (!user) {
        setIsAuthDialogOpen(true);
      }
    },
    [onClick, user]
  );

  let iconClassName = 'transition-colors duration-200 ease-in-out stroke-[2px]';
  if (saved) {
    iconClassName += ' text-red-medium stroke-red-medium';
  } else if (dark) {
    iconClassName +=
      ' text-black stroke-white group-hover:text-black group-hover:stroke-red-dark';
  } else {
    iconClassName +=
      ' text-white/20 stroke-neutral-800 group-hover:text-white group-hover:stroke-red-dark';
  }

  return (
    <>
      <Button
        {...rest}
        className={`flex h-[${buttonDimensions}px] w-[${buttonDimensions}px] flex-col items-center justify-center rounded-full ${dark ? 'border-black' : 'border-white'} border-[1px] px-xs py-[5px] shadow-m ${backgroundClassName}`}
        disabled={loading}
        onTouchEnd={handleButtonAction}
        onClick={handleButtonAction}
        aria-label={saved ? 'Remove from saved' : 'Save to favorites'}
      >
        <HeartIcon
          width={iconSize}
          height={iconSize}
          className={iconClassName}
        />
      </Button>
      <AuthDialog
        isAuthDialogOpen={isAuthDialogOpen}
        backGroundImage={authDialogImage}
        setIsAuthDialogOpen={setIsAuthDialogOpen}
        renderIcon={
          <HeartIcon className="h-xl w-xl stroke-red-medium text-red-medium" />
        }
        title="Save Vehicle"
        description="Sign in or create an account to save your EVs and stay updated."
      />
    </>
  );
};
